import React from 'react';
import  background from '../images/background.png';

const Home = (props) => {

   

    return (
        
        <div className="d-flex justify-content-center">
        <img src={background} className="img-fluid mt-5" alt="CONDEP" />
        </div>
        

    )

}
export default Home;