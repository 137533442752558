import React,{useEffect, useRef} from 'react'
import OlMap from 'ol/Map';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import OlView from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OlFeature from 'ol/Feature';
import {Style, Icon} from 'ol/style'
import OLPoint from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

import "./styles/FormFinishMap.css"

const FormFinishMapItem = (props) => {
    const {detail} = props;

    // const [ map, setMap ] = useState()

    const mapElement = useRef()

    // const mapRef = useRef()
    // mapRef.current = map

    var lat = 0
    var lon = 0
    var coordsSplit = detail.response.split(",")
    if (coordsSplit.length === 2) {
        lat = parseFloat(coordsSplit[0])
        lon = parseFloat(coordsSplit[1])
    }

    const map = new OlMap({
        layers: [
            new OlLayerTile({
                name: 'OSM',
                source: new OlSourceOsm()
            })
        ],
        view: new OlView({
            center: fromLonLat([lon, lat]),
            zoom: 16
        }),
        controls: []
    });

    useEffect(() => {
        map.setTarget(mapElement.current)

        var iconFeature = new OlFeature({
            geometry: new OLPoint(fromLonLat([lon, lat])),
        });

        iconFeature.setStyle(new Style({
            image: new Icon(/** @type {module:ol/style/Icon~Options} */({
                anchor: [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: require('../images/map_marker.png')
            }))
        }));

        map.addLayer(new VectorLayer({
            source: new VectorSource({
                features: [iconFeature]
            })
        }));

    }, [lat, lon, map])

    
    return (
        <div ref={mapElement} className="ol-map"></div>
    )
}

export default FormFinishMapItem;