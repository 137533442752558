import React from 'react'
import {URL_BASE_WS} from '../Constanst'
import FormFinishMapItem from '../components/FormFinishMapItem'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faFile, faTextWidth, faListUl, faQrcode, faImage, faCalendarAlt, faClock, faKey, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";


const FormListItem = (props) => {

    const {item,apikey} = props;

    if (item.entryType === 8) {

    }

    return (
        <div className="card mb-1">
            <div className="card-header ">
                <div className='row'>
                    <div className='col-10'>
                        {
                            item.entryType===100 && ( <FontAwesomeIcon icon={faFile} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===0 && ( <FontAwesomeIcon icon={faTextWidth} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===1 && ( <FontAwesomeIcon icon={faQuestionCircle} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===2 && ( <FontAwesomeIcon icon={faListUl} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===3 && ( <FontAwesomeIcon icon={faCalendarAlt} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===4 && ( <FontAwesomeIcon icon={faClock} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===5 && ( <FontAwesomeIcon icon={faClock} className='mr-2 text-secondary' /> )
                        }
                        
                        {
                            item.entryType===6 && ( <FontAwesomeIcon icon={faImage} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===7 && ( <FontAwesomeIcon icon={faQrcode} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===8 && ( <FontAwesomeIcon  icon={faKey} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===9 && ( <FontAwesomeIcon  icon={faMapMarkerAlt} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===100?'Pagina': item.text
                        }
                        
                    </div>
                    <div className='col-2'>
                    
                    </div>
                </div>
            </div>
            {
                item.entryType>0 && (
                    <div className='card-body'>
                        {
                            item.entryType===6 && item.response !== undefined?
                            <img 
                                src={`${URL_BASE_WS}/activities/photos?photoId=${item.response}&apikey=${apikey}`} 
                                //data-id={props.schedules.photoId} 
                                alt='foto' 
                                //  onClick={props.handleClickImage} 
                                className='img-thumbnail'  
                            />
                            : item.entryType===8 && item.response !== undefined?
                            <img
                                src={`${URL_BASE_WS}/activities/signatures?signatureId=${item.response}&apikey=${apikey}`} 
                                // data-id={props.schedules.photoId} 
                                alt='firma' 
                                //onClick={props.handleClickImage} 
                                className='img-thumbnail' 
                            />
                            : item.entryType === 9 && item.response !== undefined?
                            <FormFinishMapItem detail={item} />
                            : item.response
                        }
                    </div>
                )
            }
        </div>
    )
}
export default FormListItem;