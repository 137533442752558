import React, { Fragment } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLandmark, faHourglassEnd,faHourglassStart,faClock,faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";

const RouteFinishItemList = (props) => {
    const {level,route,index,handleClickDetail}=props;
    //const carBg= route.status===1?' text-white bg-secondary':'';
    const carBg= '';
    return (
        <div  className={`card   border-secondary mb-3 ${carBg}`}  >
            <div className="card-header ">
                <div className='row'>
                    <div className='col-10'>
                           <strong className='ml-1'>{route.userName}</strong>                         
                    </div>
                    <div className='col-2  justify-content-end'>
                    <FontAwesomeIcon icon={faMapMarkedAlt}  
                        onClick={handleClickDetail} 

                        data-index={index} 
                        className='pointer text-primary ml-3'/>
                        
                    </div>
                </div>
            </div>
            <div className='card-body'>
                {
                    route.clientName && (level===0) && (
                        <Fragment>
                        <FontAwesomeIcon icon={faLandmark}  /> <span className='card-text ml-2'>{route.clientName }</span><br/>
                        </Fragment>
                    )
                }
                {
                    route.startDate && (
                        <Fragment>
                            <FontAwesomeIcon icon={faHourglassStart}  /> <span className='card-text ml-2'>{route.startDate }</span><br/>
                            <FontAwesomeIcon icon={faHourglassEnd}  /> <span className='card-text ml-2'>{route.endDate }</span><br/>
                            <FontAwesomeIcon icon={faClock}  /> <span className='card-text ml-2'>{`${route.duration} min`}</span>
                        </Fragment>
                    )
                }
                
                    
                
            </div>
        </div>
    )

}
export default  RouteFinishItemList;
