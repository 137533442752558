import firebase from "firebase";
import Swal2 from "sweetalert2";

const config = {
    apiKey: "AIzaSyDhgtOq4z4ottZ141ETF-WLHtRlqxQ_dk8",
    authDomain: "condep-b0d75.firebaseapp.com",
    databaseURL: "https://condep-b0d75.firebaseio.com",
    projectId: "condep-b0d75",
    storageBucket: "condep-b0d75.appspot.com",
    messagingSenderId: "618813943206",
    appId: "1:618813943206:web:05fdc0c4f9860fedd33367",
    measurementId: "G-1T8ZBNN2E8"
}

export const preguntarPermisos = async () => {
    try {
        firebase.initializeApp(config);
        const messaging = firebase.messaging();
        const permission = await Notification.requestPermission();
        //console.log(permission)
        if (permission === "denied") {
            return;
        } else if (permission === "default") {
            return;
        }
        const token = await messaging.getToken();
        //console.log("Token: ", token);
        messaging.onMessage(function(payload) {
            Swal2.fire({
                icon: "warning",
                title: "Alerta de botón de pánico",
            });
        });
        return token;
    } catch (error) {
        console.error(error);
    }
}