import React from 'react';
import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay  } from "@fortawesome/free-solid-svg-icons";
import es from 'date-fns/locale/es';
registerLocale('es', es)


const FiltroFechas = (props) => {

    const {handleStarDate,handleEndDate,startDate,endDate} = props;

    const now = new Date();
    const pastDate180 =  new Date().setDate(now.getDate() - 91);
    const ButtonDateCustom = ({ value, onClick }) => (
        
        <div className="input-group flex-nowrap">
            <div className="input-group-prepend">
                <span className="input-group-text" id="addon-wrapping">
                <FontAwesomeIcon icon={faCalendarDay} />
                </span>
            </div>
            <input type='text' className="form-control" value={value} onClick={onClick}/>
        </div>

    );
    return (
       
                   
            <div className="input-group  input-group-sm  ">
            <DatePicker
                locale="es"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={handleStarDate}
                customInput={<ButtonDateCustom />}
                minDate={pastDate180}
                maxDate={now}
            />
            <div class="input-group-append">
                <span class="input-group-text">   - </span>
            </div>
            
            <DatePicker
                locale="es"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={handleEndDate}
                customInput={<ButtonDateCustom />}
                minDate={pastDate180}
                maxDate={now}
            />

           
            
        </div>




                
        

        

        
    );
}
export default FiltroFechas;