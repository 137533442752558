import React,{useState, useEffect,Fragment} from 'react';
import {connect} from 'react-redux';
import { useForm } from "react-hook-form";
import {URL_BASE_WS} from '../Constanst'
import MiniLoader from './MiniLoader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey  } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from './ChangePasswordModal'
import swal from 'sweetalert';
import qs from 'querystring'

const   UserFormEdit = (props) => {
    const {user,userEdit} = props;
    const hasUser = Object.keys(user).length>0;
    const sePuedeEditar = userEdit.level===0?false: userEdit.level===1 && user.level>0?false:true ;

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            name:userEdit.name,
            username:userEdit.username,
        }
    });
    const [state,setState] = useState({
        loading :false, 
    });
    const [stateModal,setStateModal] = useState({
        modalIsOpen :false
    });
    const [formModal,setFormModal] = useState({
        userId:userEdit.userId,
        
    });

    const [permisosChecked,setPermisosChecked] = useState({
        CHK_1:(userEdit.permissions & 1) !== 0,
        CHK_2:(userEdit.permissions & 2) !== 0,
        CHK_3:(userEdit.permissions & 4) !== 0,
        CHK_4:(userEdit.permissions & 8) !== 0,
        CHK_5:(userEdit.permissions & 16) !== 0,
        CHK_6:(userEdit.permissions & 32) !== 0,
        CHK_7:(userEdit.permissions & 64) !== 0,
        CHK_8:(userEdit.permissions & 128) !== 0,
        CHK_9:(userEdit.permissions & 256) !== 0,
        CHK_10:(userEdit.permissions & 512) !== 0,
        CHK_11:(userEdit.permissions & 1024) !== 0,
        CHK_12:(userEdit.permissions & 2048) !== 0,
        CHK_13:(userEdit.permissions & 4096) !== 0,
        CHK_14:(userEdit.permissions & 8192) !== 0,
        CHK_15:(userEdit.permissions & 16384) !== 0,
        CHK_16:(userEdit.permissions & 32768) !== 0,
        CHK_17:(userEdit.permissions & 65536) !== 0,
        CHK_18:(userEdit.permissions & 131072) !== 0,
        CHK_19:(userEdit.permissions & 262144) !== 0,
        CHK_20:(userEdit.permissions & 524288) !== 0,
        CHK_21:(userEdit.permissions & 1048576) !== 0,
        CHK_22:(userEdit.permissions & 2097152) !== 0,
        CHK_23:(userEdit.permissions & 4194304) !== 0,
        CHK_24:(userEdit.permissions & 8388608) !== 0
    });
    const [permisos,setPermisos] = useState({
        CHK_1:userEdit.permissions & 1,
        CHK_2:userEdit.permissions & 2,
        CHK_3:userEdit.permissions & 4,
        CHK_4:userEdit.permissions & 8,
        CHK_5:userEdit.permissions & 16,
        CHK_6:userEdit.permissions & 32,
        CHK_7:userEdit.permissions & 64,
        CHK_8:userEdit.permissions & 128,
        CHK_9:userEdit.permissions & 256,
        CHK_10:userEdit.permissions & 512,
        CHK_11:userEdit.permissions & 1024,
        CHK_12:userEdit.permissions & 2048,
        CHK_13:userEdit.permissions & 4096,
        CHK_14:userEdit.permissions & 8192,
        CHK_15:userEdit.permissions & 16384,
        CHK_16:userEdit.permissions & 32768,
        CHK_17:userEdit.permissions & 65536,
        CHK_18:userEdit.permissions & 131072,
        CHK_19:userEdit.permissions & 262144,
        CHK_20:userEdit.permissions & 524288,
        CHK_21:userEdit.permissions & 1048576,
        CHK_22:userEdit.permissions & 2097152,
        CHK_23:userEdit.permissions & 4194304,
        CHK_24:userEdit.permissions & 8388608,
    });

    useEffect( () => {
        if(hasUser===false){
            props.history.replace('/');
        }
    });

    const onSubmit =   async data => {
        try {
            setState({loading:true})
            let contador = 0;
            var conWeb = permisos["CHK_2"] !== 0;
            var conApp = permisos["CHK_1"] !== 0;
            Object.keys(permisos).forEach((key) => {
                contador = contador + permisos[key];
            });
            if (!conWeb) {
                contador = contador & 0x7FF00003;
            }
            if (!conApp) {
                contador = contador & 0x7F0FFFFF;
            }
            
            data['permissions'] = contador;
            data['userId'] = userEdit.userId;
            
            //console.log(data);
            let response = await fetch(`${URL_BASE_WS}/users`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(data),
            });
            response = await response.json();
            //console.log(response);
            setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } else {
                props.history.push('/users');
            }
        } catch(error){
            setState({loading:false})
            swal("Algo salio mal!", `${error.message}`, "error")
        }
        

    };

    const toggleCheckbox = (e) => {
        if(e.target.checked){            
            setPermisos({...permisos,
                [e.target.name] : Number(e.target.value)
            })
            setPermisosChecked({...permisosChecked,
                [e.target.name] : true
            })
        } else{
            setPermisos({...permisos,
                [e.target.name] :0
            })
            setPermisosChecked({...permisosChecked,
                [e.target.name] : false
            })
        }
    }

    const handleOpenModal = e => {
        //console.log("handleOpenModal");
        setStateModal({ modalIsOpen: true});
    };
    
    const handleCloseModal = e => {
        setStateModal({ modalIsOpen: false });
    };

    const onChangePassword = e => {
        setFormModal({
            ...formModal,
            [e.target.name] : e.target.value
        });


    };
    

    const handleChangePassword = async e => {
        //console.log(formModal.password);

        if(formModal.password === undefined ){
            setState({
                errorModal : 'Captura la  contraseña'
            })
            return ;
        }
        if(formModal.passwordConfirm === undefined ){
            setState({
                errorModal : 'Captura la confirmación de contraseña'
            })
            return ;
        }
        if(formModal.passwordConfirm !== formModal.password ){
            setState({
                errorModal : 'Contraseñas deben ser iguales'
            })
            return ;
        }


        setStateModal({ modalIsOpen: false });
        
        
        
        
        setState({loading:true})



        try {
            let response = await fetch(`${URL_BASE_WS}/users/password`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(formModal),
            });
            response = await response.json();
            //console.log(response);
            setState({loading:false})
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } 
        } catch(error){
            setState({loading:false})
            swal("Algo salio mal!", `${error.message}`, "error")
        }
    };

    
    return(
             <div>
                <h1>Usuario</h1>
                <form>
                    <fieldset></fieldset>
                <div className='container'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Nombre</label>
                                {
                                    sePuedeEditar === true ?
                                <input 
                                ref={register({ required: true })}
                                onChange={props.onChange}
                                className='form-control' 
                                type='text' 
                                name='name' 
                                
                                /> :
                                <input 
                                ref={register({ required: true })}
                                //onChange={props.onChange}
                                className='form-control' 
                                type='text' 
                                name='name' 
                                readOnly
                                
                                /> 
                                }
                                {errors.name && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label>* Usuario </label>
                                <input 
                                ref={register({ required: true })}
                                className='form-control' 
                                type='text' 
                                readOnly
                                name='username' 
                                />
                                {errors.username && ( <span className='text-danger'>Obligatorio</span>)}  
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                            <label> </label>
                            
                            <div className="input-group">
                                <div className="input-group-append">
                                    <button  onClick={handleOpenModal} className="btn btn-outline-secondary btn-danger" type="button">
                                     <FontAwesomeIcon icon={faKey}  /> Cambiar Contraseña
                                        </button>
                                </div>
                            </div>

                            </div>
                        

                        </div>
                    </div>

                    {
                        sePuedeEditar===false?null:
                        <Fragment>
                            <fieldset><h4>Acceso</h4></fieldset>
                            <div className='row'>
                                <div className='col-2'>
                                    <div className="form-check">
                                        <input className="form-check-input" onChange={toggleCheckbox} name='CHK_1' checked={permisosChecked.CHK_1} value='1' type="checkbox" id='defaultCheck1'  />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            App
                                        </label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-check">
                                        <input className="form-check-input" onChange={toggleCheckbox} name='CHK_2' checked={permisosChecked.CHK_2} type="checkbox" value="2" id='defaultCheck2' />
                                        <label className="form-check-label" htmlFor="defaultCheck2">
                                            Web
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Fragment>

                    }

                    {
                        sePuedeEditar===false?null :
                        permisos.CHK_1 === 1 && (
                            <Fragment>
                                <hr></hr>
                                <fieldset><h4>App</h4></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='1048576' checked={permisosChecked.CHK_21} name='CHK_21' type="checkbox" id='defaultCheck21'  />
                                            <label className="form-check-label" htmlFor="defaultCheck21">
                                                Horarios
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='2097152' checked={permisosChecked.CHK_22} name='CHK_22' type="checkbox" id='defaultCheck22'  />
                                            <label className="form-check-label" htmlFor="defaultCheck22">
                                                Recorridos
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='4194304' checked={permisosChecked.CHK_23} name='CHK_23' type="checkbox" id='defaultCheck23'  />
                                            <label className="form-check-label" htmlFor="defaultCheck23">
                                                Actividades
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='8388608' checked={permisosChecked.CHK_24} name='CHK_24' type="checkbox" id='defaultCheck24'  />
                                            <label className="form-check-label" htmlFor="defaultCheck24">
                                                Botón de Pánico
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                    
                    {
                        sePuedeEditar===false?null :
                        permisos.CHK_2 === 2 && (
                            <Fragment>
                                <hr></hr>
                                <fieldset><h4>Web</h4></fieldset>
                                <fieldset><strong>Usuarios</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='4' checked={permisosChecked.CHK_3} name='CHK_3' type="checkbox" id='defaultCheck3'  />
                                            <label className="form-check-label" htmlFor="defaultCheck3">
                                                Agregar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} type="checkbox" checked={permisosChecked.CHK_4} name='CHK_4' value="8" id='defaultCheck4' />
                                            <label className="form-check-label" htmlFor="defaultCheck4">
                                            Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} name='CHK_5' checked={permisosChecked.CHK_5}  type="checkbox" value="16" id='defaultCheck5' />
                                            <label className="form-check-label" htmlFor="defaultCheck5">
                                            Eliminar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} type="checkbox" checked={permisosChecked.CHK_6} name='CHK_6' value="32" id='defaultCheck6' />
                                            <label className="form-check-label" htmlFor="defaultCheck6">
                                            Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <fieldset><strong>Horarios</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='64' checked={permisosChecked.CHK_7} name='CHK_7' type="checkbox" id='defaultCheck7'  />
                                            <label className="form-check-label" htmlFor="defaultCheck7">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Recorrido</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='128' checked={permisosChecked.CHK_8} name='CHK_8' type="checkbox" id='defaultCheck8'  />
                                            <label className="form-check-label" htmlFor="defaultCheck8">
                                            Solicitar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='256' checked={permisosChecked.CHK_9} name='CHK_9' type="checkbox" id='defaultCheck9'  />
                                            <label className="form-check-label" htmlFor="defaultCheck9">
                                            Consultar activos
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='512' checked={permisosChecked.CHK_10} name='CHK_10' type="checkbox" id='defaultCheck10'  />
                                            <label className="form-check-label" htmlFor="defaultCheck10">
                                            Consultar historial
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Botón de Pánico</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='1024' checked={permisosChecked.CHK_11} name='CHK_11' type="checkbox" id='defaultCheck11'  />
                                            <label className="form-check-label" htmlFor="defaultCheck11">
                                            Atender
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='2048' checked={permisosChecked.CHK_12} name='CHK_12' type="checkbox" id='defaultCheck12'  />
                                            <label className="form-check-label" htmlFor="defaultCheck12">
                                            Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='4096' checked={permisosChecked.CHK_13} name='CHK_13' type="checkbox" id='defaultCheck13'  />
                                            <label className="form-check-label" htmlFor="defaultCheck13">
                                            Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Actividades</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='8192' checked={permisosChecked.CHK_14} name='CHK_14' type="checkbox" id='defaultCheck14'  />
                                            <label className="form-check-label" htmlFor="defaultCheck14">
                                                Crear
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='16384' checked={permisosChecked.CHK_15} name='CHK_15' type="checkbox" id='defaultCheck15'  />
                                            <label className="form-check-label" htmlFor="defaultCheck15">
                                                Enviar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='32768' checked={permisosChecked.CHK_16} name='CHK_16' type="checkbox" id='defaultCheck16'  />
                                            <label className="form-check-label" htmlFor="defaultCheck16">
                                                Eliminar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='65536' checked={permisosChecked.CHK_17} name='CHK_17' type="checkbox" id='defaultCheck17'  />
                                            <label className="form-check-label" htmlFor="defaultCheck17">
                                                Editar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='131072' checked={permisosChecked.CHK_18} name='CHK_18' type="checkbox" id='defaultCheck18'  />
                                            <label className="form-check-label" htmlFor="defaultCheck18">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset><strong>Reportes</strong></fieldset>
                                <div className='row'>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='262144' checked={permisosChecked.CHK_19} name='CHK_19' type="checkbox" id='defaultCheck19'  />
                                            <label className="form-check-label" htmlFor="defaultCheck19">
                                                Generar
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={toggleCheckbox} value='524288' checked={permisosChecked.CHK_20} name='CHK_20' type="checkbox" id='defaultCheck20'  />
                                            <label className="form-check-label" htmlFor="defaultCheck20">
                                                Consultar
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </Fragment>
                           
                       )
                        
                    }
                    <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'>
                        {

                            
                            sePuedeEditar ===true? state.loading?
                            <div className="d-flex justify-content-center"><MiniLoader/></div>:
                            <button type='button' 
                            onClick={handleSubmit(onSubmit)}
                            className="btn btn-primary mt-4">
                                Guardar
                            </button>:state.loading?
                            <div className="d-flex justify-content-center"><MiniLoader/></div>:null
                
                        }

                            
                        </div>
                    </div>
                </div>



                

                    






                    
                </form>
                <ChangePasswordModal
                    isOpen={stateModal.modalIsOpen}
                    onClose={handleCloseModal}
                    onChange ={onChangePassword}
                    handleChangePassword={handleChangePassword}
                    error = {state.errorModal}
                 />
            </div>
        

    );
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(UserFormEdit);
