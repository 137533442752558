import React  from 'react';
import {connect} from 'react-redux';


import FormFinishList from '../components/FormFinishList'



const  FormDetailFinish = (props) => {
    const {user} = props;
    const detail =props.location.state.detail;
    
    
    let entries = [];
    let page = -1;
    let index = 0;
    detail.entries.forEach ( entry => {
        if (entry.entryType === 100) {
            entries.push([]);
            page += 1;
            index = 0;
        } else {
            let tempEntry = {
                page: page,
                index: index,
                entryType: entry.entryType,
                required: entry.required,
                text: entry.text,
                data: entry.data,
                responseEntryId:entry.responseEntryId,
                response:entry.response
            }
            entries[entries.length - 1].push(tempEntry)
            index += 1;
        }
    });
    /*const [form,setForm] = useState({
        list:entries
    })*/
    
    
  
    

   


    

    return (
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-8  mt-3'>
                                        <h6><strong>Actividad: </strong>  {detail.name}</h6>
                                        <h6><strong>Nombre: </strong>  {detail.responseUserName}</h6>
                                        <h6><strong>Ubicación: </strong> <a  target='_blank' rel="noopener noreferrer" href={`https://maps.google.com/?q=${detail.responseLatitude},${detail.responseLongitude}`} className='card-text text-dark ml-3'>{`${detail.responseLatitude},${detail.responseLongitude}`}</a>      </h6>
                                        
                                        

                              
                        </div>
                    </div>
                    <div className='row'>
                    <div className='col-2'></div>
                        <div className='col-8  mt-3'>
                            <FormFinishList  
                                //forms={form.list}
                                forms={entries}
                                user={user}
                                
                            />
                        </div>
                    </div>
                </div>

               
            
        
    )

}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(FormDetailFinish);

