import React from 'react'

import  Report from '../components/Report'

class RouteHistory extends React.Component {
    constructor(props){
        super(props);
        //console.log("1. constructor ");
        this.state = {
            loading: true,
            error: null,
            data: [
                {title:'20/06/2020',subtitle:'1 minutos',descripcion:'Juan Perez Lopez',descripcion2:'Francisco Salvatierra'},
                {title:'17/06/2020',subtitle:'12 minutos',descripcion:'Luis Luna Castro',descripcion2:'Francisco Salvatierra'},
                {title:'15/06/2020',subtitle:'30 minutos',descripcion:'Mario Medina',descripcion2:'Francisco Salvatierra'},
                {title:'12/06/2020',subtitle:'40 minutos',descripcion:'Manuel Rosales',descripcion2:'Francisco Salvatierra'},
                {title:'10/06/2020',subtitle:'4 minutos',descripcion:'Antonio Soto',descripcion2:'Francisco Salvatierra'},
                {title:'10/06/2020',subtitle:'90 minutos',descripcion:'Francisco Salvatierra',descripcion2:'Francisco Salvatierra'}
            ],
            //data: undefined,
          };

    }

    render(){
        return(
            <Report 
            titleReport='Historial  Recorridos' 
            reportList={this.state.data} 
            title='Fecha'
            subtitle='Duración'
            description='Usuario'
            
            />
        )
    }

}
export default RouteHistory;