import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faFile, faTextWidth, faListUl, faQrcode, faImage, faCalendar, faCalendarAlt, faClock, faKey, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";


const FormAddItem = (props) => {
    const {handleClick} = props;
   
    return (
        <div className='container'>
            
            <div onClick={handleClick} data-id={100} className="card pointer text-white bg-secondary mt-2 w-75 ">
                <div className="card-header">
                    <FontAwesomeIcon icon={faFile} className='mr-2'  />
                    Pagina    
                </div>
            </div>
            <div onClick={handleClick} data-id={0} className="card pointer text-white bg-secondary mt-2 w-75  ">
                <div className="card-header">
                    <FontAwesomeIcon icon={faTextWidth} className='mr-2'  />
                    Informativo    
                </div>
            </div>
            <div onClick={handleClick}  data-id={1} className="card pointer text-white bg-secondary mt-2 w-75  " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faQuestionCircle}  className='mr-2' />
                    Texto
                </div>
            </div>
            <div onClick={handleClick} data-id={2} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faListUl}  className='mr-2' />
                    Opcion Multiple
                </div>
            </div>
            
            <div onClick={handleClick} data-id={3} className="card pointer text-white bg-secondary mt-2 w-75  " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faCalendar}  className='mr-2' />
                    Fecha
                </div>
            </div>
            <div onClick={handleClick} data-id={4} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faClock}  className='mr-2' />
                    Hora
                </div>
            </div>
            <div onClick={handleClick} data-id={5} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faCalendarAlt}  className='mr-2' />
                    Fecha y Hora
                </div>
            </div>
            <div onClick={handleClick} data-id={6} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faImage}  className='mr-2' />
                    Imagen
                </div>
            </div>
            <div onClick={handleClick} data-id={7} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faQrcode}  className='mr-2' />
                    QR
                </div>
            </div>
            <div onClick={handleClick} data-id={8} className="card pointer text-white bg-secondary mt-2 w-75 " >
                <div className="card-header">
                    <FontAwesomeIcon icon={faKey}  className='mr-2' />
                    Firma
                </div>
            </div>
            <div onClick={handleClick} data-id={9} className="card pointer text-white bg-secondary mt-2 w-75 ">
                <div className="card-header">
                    <FontAwesomeIcon icon={faMapMarkerAlt}  className='mr-2' />
                    Ubicación
                </div>
            </div>

        </div>
    )

}
export default FormAddItem;