import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import swal from 'sweetalert';
import qs from 'querystring'
import PageLoading from '../components/PageLoading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import ActivityList from '../components/ActivityList';
import FormModalUsers from '../components/FormModalUsers'; 


const Forms = (props) => {

    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = user.level;
    const puedeEditar = user.level<2 ? true :   (user.permissions & 65536) !== 0 ? true : false;
    const puedeEliminar = user.level<2 ? true :   (user.permissions & 32768) !== 0 ? true : false;
    const puedeEnviar = user.level<2 ? true :   (user.permissions & 16384) !== 0 ? true : false;
    

    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    });

    async function fetchForms() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/activities`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        //console.log(response);
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                    props.history.replace('/');
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.activities})
        }
    }
    useEffect( () => {
        //console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchForms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //************Edit************* */

    const handleClickEdit = (e) =>{
        let index  = Number(e.currentTarget.dataset.index);


        //console.log(state.data[index]);
        
        props.history.push({
            pathname: `/form/edit`,
            state: { detail: state.data[index] }
          })

    }
    
    
    //************Users************* */
    const [stateUsers,setStateUsers] = useState({ 
        activityId:0,
        modalIsOpen:false,
        data: []
    }) 

    const handleCloseModalUsers = () =>{
        setStateUsers({
            activityId:0,
            modalIsOpen:false,
            data: []
        })
    }


    const handleClickUsers= (e) =>{
        let index  = Number(e.currentTarget.dataset.index);

        //console.log(state.data[index]);
        
        fetchUsers(state.data[index].activityId,state.data[index].clientId);

    }

    const handleClickCheck = (e) => {
        let dataTemp=stateUsers.data;
        let actTemp= stateUsers.activityId;
        let itemUser=dataTemp[e.target.value];
        if(e.target.checked){
            itemUser.allowed=1;
        } else {
            itemUser.allowed=0;
        }
        dataTemp[e.target.value]=itemUser;
        setStateUsers({
            activityId:actTemp,
            modalIsOpen:true,
            data : dataTemp
        })
    }
    const handleSaveUsers =  async () => {
        try {
            let dataTemp=stateUsers.data;
            let dataAllowed = dataTemp.filter(function (item) {
                return item.allowed === 1;
            });
            let usersStr = dataAllowed.reduce((resp, item) => `${resp}${item.userId},` ,'')
            let formUsers = {
                activityId:stateUsers.activityId,
                clientId:user.clientId,
                users:  usersStr.substring(0, usersStr.length - 1)
            }

            let response = await fetch(`${URL_BASE_WS}/activities/users`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': user.apikey
                },
                body:  qs.stringify(formUsers),
            });
            response = await response.json();
            //console.log(response);
            
            if(response.error){
                swal("Algo salio mal!", `${response.message}`, "error")
            } else {
                setStateUsers({
                    activityId:0,
                    modalIsOpen:false,
                    data: []
                })
                fetchForms();
            }
        } catch(error){
            
            swal("Algo salio mal!", `${error.message}`, "error")
        }
    }

    async function fetchUsers(activityId,clientId) {
        
        let response =  await fetch(`${URL_BASE_WS}/activities/users?activityId=${activityId}&clientId=${clientId}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        //console.log(response);
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                    props.history.replace('/');
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
        } else {
            setStateUsers({ activityId:activityId,modalIsOpen:true,data:response.users})
        }
    }

    /***********Delete**************** */
    const handleClickDelete = (e) =>{
        let id  = Number(e.currentTarget.dataset.id);
        //console.log(id);


        swal({
            title: "Eliminar",
            text: "Estas por eliminar Actividad",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                (async () => {
                    let formDelete = {
                        activityId:id
                    };
                    let response =  await fetch(`${URL_BASE_WS}/activities`, {
                        method: 'delete',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                            'Authorization': user.apikey
                        },
                        body:  qs.stringify(formDelete),
                    });
                    response = await response.json();
                    if(response.error === true){
                        swal("Algo salio mal!", `${response.message}`, "error")
                    } else {
                        fetchForms();
                    }


                })();
            } else {
              //swal("Your imaginary file is safe!");
            }
          });




 

    }


    if (state.loading === true) {
        return <PageLoading />;
      }


    return (
        <React.Fragment>
            <div className='container'>
                <div className='row mb-3 mt-4 justify-content-start'>
                    <div className='col-4'>
                            <h2>Actividades</h2>
                    </div>
                    <div className='col-4 '></div>
                    <div className='col-4 '>
                    {
                            user.level < 2 ||  (user.permissions & 8192) !== 0?
                        <Link to='/form/new' className='btn btn-primary btn-sm'>
                            <FontAwesomeIcon icon={faPlus} className='text-white mr-1' />
                                 Actividad
                        </Link>:null
                    }


                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <ActivityList
                            level={level}
                            activities={state.data}
                            handleClickDelete={handleClickDelete}
                            handleClickUsers={handleClickUsers}
                            handleClickEdit={handleClickEdit}
                            puedeEditar={puedeEditar}
                            puedeEliminar={puedeEliminar}
                            puedeEnviar={puedeEnviar}
                        />
                    </div>
                </div>
                <FormModalUsers
                    users={stateUsers.data}                               
                    isOpen={stateUsers.modalIsOpen}                               
                    onClose={handleCloseModalUsers}
                    handleClickCheck={handleClickCheck}
                    handleSaveUsers={handleSaveUsers}
                    />
            </div>
        </React.Fragment>
    )
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(Forms);
