import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch} from "@fortawesome/free-solid-svg-icons";
import FiltroFechas from './FiltroFechas';

const RouteESearchForm = (props) =>{

    const {startDate,endDate,handleStarDate,handleEndDate,handleSearchUsers,refUser,puedeConsultar}=props;
    
    return (
        <div>
        <h1>Recorrido Especifico</h1>
            <form>
                <fieldset></fieldset>
                    <div className='row'>
                    <div className='col-2'></div>
                        <div className='col-3'>
                            <div className='form-group'>
                                <label>Usuario</label>
                                <input 
                                ref={refUser}
                                className='form-control' 
                                type='text' 
                                name='userName' 

                                />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Fecha</label>
                                <FiltroFechas 

                                handleStarDate={handleStarDate}
                                handleEndDate={handleEndDate}
                                startDate={startDate}
                                endDate={ endDate}

                                />
                                </div>
                            
                        </div>
                    </div>
                    <div className='row mb-4'>
                    <div className='col-5'></div>
                        <div className='col-2 '>
                            {
                                puedeConsultar===true && (
                                    <button 
                                type='button' 
                                className='btn btn-secondary'
                                onClick={handleSearchUsers}
                             >
                             <FontAwesomeIcon icon={faSearch} />  Buscar
                            </button>

                                )
                            }
                            
                            
                        </div>

                    </div>
            </form>
        </div>
    )
}

export default RouteESearchForm;