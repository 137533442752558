import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faFile, faTextWidth, faListUl, faQrcode, faImage, faCalendarAlt, faClock, faKey, faMapMarkerAlt, faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";


const FormListItem = (props) => {

    const {item} = props;

    return (
        <div className="card mb-1">
            <div className="card-header ">
                <div className='row'>
                    <div className='col-10'>
                       
                        {
                            item.entryType===100 && ( <FontAwesomeIcon icon={faFile} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===0 && ( <FontAwesomeIcon icon={faTextWidth} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===1 && ( <FontAwesomeIcon icon={faQuestionCircle} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===2 && ( <FontAwesomeIcon icon={faListUl} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===3 && ( <FontAwesomeIcon icon={faCalendarAlt} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===4 && ( <FontAwesomeIcon icon={faClock} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===5 && ( <FontAwesomeIcon icon={faClock} className='mr-2 text-secondary' /> )
                        }
                        
                        {
                            item.entryType===6 && ( <FontAwesomeIcon icon={faImage} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===7 && ( <FontAwesomeIcon icon={faQrcode} className='mr-2 text-secondary' /> )
                        }

                        {
                            item.entryType===8 && ( <FontAwesomeIcon  icon={faKey} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===9 && ( <FontAwesomeIcon  icon={faMapMarkerAlt} className='mr-2 text-secondary' /> )
                        }
                        {
                            item.entryType===100?'Pagina': item.text
                        }
                        
                    </div>
                    <div className='col-2'>
                        {
                            item.entryType!==100 && (
                                <FontAwesomeIcon  icon={faPencilAlt} data-page={props.page} data-index={props.index} onClick={props.onEditItem} className='pointer mr-3 text-primary' />
                            )
                        }
                        <FontAwesomeIcon  icon={faTrash} data-page={props.page} data-index={props.index} onClick={props.onDeleteItem} className='pointer mr-2 text-danger' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FormListItem;