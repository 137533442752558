import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import swal from 'sweetalert';
import ModalMapa from '../components/ModalMapa';
import PageLoading from '../components/PageLoading';
import ActivityFinishList from '../components/ActivityFinishList';


const FormsFinish = (props) => {

    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    const level = user.level;
    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    });

    /*************** FECHAS ******************* */
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 6)));
    const [endDate, setEndDate] = useState(new Date());
    const [params, setParams] = useState('');
    
    const handleStarDate = (date) =>{
        if(date > endDate){
            setEndDate(date)
        } else {
            const limiteEnd = new Date(new Date(date).setDate(date.getDate() + 30))
            if(endDate > limiteEnd){
                setEndDate(limiteEnd)
            }
        }
        setStartDate(date);
    }
    const handleEndDate = (date) =>{
        if (date < startDate) {
            setStartDate(date)
        } else {
            const minimoStart = new Date(new Date(date).setDate(date.getDate() - 30))
            if (startDate < minimoStart) {
                setStartDate(minimoStart)
            }
        }
        setEndDate(date);
    }
    
    const handleSyncFecha = () => {
        if (startDate !== undefined && endDate !== undefined) {
            if (startDate <= endDate) {
                setParams(`?startDate=${formattedDate(startDate)}&endDate=${formattedDate(endDate)}`);
            } else {
                swal("Algo salio mal!", 'Revisa los valores de fechas', "error")
            }
        } else {
            swal("Algo salio mal!", 'Define fechas de busqueda', "error")
        }
    }

    function formattedDate(d = new Date()) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return `${day}/${month}/${year}`;
      }

    async function fetchForms() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/activities/responses${params}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        //console.log(response);
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                    props.history.replace('/');
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.activities})
        }
    }
    useEffect( () => {
        //console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchForms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params]);

    //************Detail************* */

    const handleClickDetail = (e) =>{
        let index  = Number(e.currentTarget.dataset.index);
        //console.log(state.data[index]);
        props.history.push({
            pathname: `/form/detail`,
            state: { detail: state.data[index] }
        })
          

    }
    
   
   
    /************MOdal Location******** */
    const handleClickLocation = (e) => {
        e.preventDefault()
        let index  = Number(e.currentTarget.dataset.index);
        //console.log('handleClickLocation:'+index);
        //console.log('handleClickLocation:'+state.data[index].latitude);
        
        setModal({
            isOpen:true,
            latitude: state.data[index].responseLatitude,
            longitude: state.data[index].responseLongitude,
            fecha:state.data[index].responseDateTime,
            titulo:state.data[index].responseUserName
            
        })
    }

    const [modal,setModal] = useState({
        isOpen: false,
        longitude:0,
        latitude:0
    })

    const handleCloseModal = () => {
        setModal({ isOpen: false,
            longitude:0,
            latitude:0 });
    };

    //************Pdf************* */
   
    const handleClickPDF = (e) =>{


        //console.log(state.data[index]);
        
        /*props.history.push({
            pathname: `/form/edit`,
            state: { detail: state.data[index] }
          })*/

    }


   

    if (state.loading === true) {
        return <PageLoading />;
      }


    return (
        <React.Fragment>
            <div className='container'>
                <div className='row mb-3 mt-4 justify-content-start'>
                    <div className='col-4'>
                            <h2>Actividades Finalizadas</h2>
                    </div>
                    <div className='col-4 '></div>
                    <div className='col-4 '>
                       
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <ActivityFinishList
                            level={level}
                            handleSyncFecha={handleSyncFecha}
                            handleStarDate={handleStarDate}
                            handleEndDate={handleEndDate}
                            startDate={startDate}
                            endDate={endDate}
                            activities={state.data}
                            handleClickDetail={handleClickDetail}
                            handleClickPDF={handleClickPDF}
                            handleClickLocation={handleClickLocation}
                            apikey={user.apikey}
                        />
                    </div>
                </div>

                <ModalMapa
                longitude={modal.longitude}
                latitude={modal.latitude}
                fecha={modal.fecha}
                titulo={modal.titulo}
                onClose={handleCloseModal}
                isOpen={modal.isOpen}
                />
            </div>
        </React.Fragment>
    )
}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(FormsFinish);
