import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import {URL_BASE_WS} from '../Constanst'
import PageLoading from '../components/PageLoading';
import swal from 'sweetalert';
import  ReportRouteGList from '../components/ReportRouteGList';
import  RouteGSearchForm from '../components/RouteGSearchForm';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel} from "@fortawesome/free-solid-svg-icons";



const  RouteReport = (props) => {

    const {user} = props;
    const hasUser = Object.keys(user).length>0;
    let refUser = useRef();
    const puedeGenerar = user.level<2 ? true :   (user.permissions & 262144) !== 0 ? true : false;
    const puedeConsultar = user.level<2 ? true :   (user.permissions & 524288) !== 0 ? true : false;


    const [state,setState] = useState({
        loading: true,
        error: null,
        data: [],
    }); 
    
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const [params, setParams] = useState('');
    
    async function fetchReportUser() {
        setState({...state,loading: true})
        let response =  await fetch(`${URL_BASE_WS}/reports/routes/general?${params}`, {
            method: 'get',
            headers: {'Authorization': user.apikey}
        });
        response = await response.json();
        //console.log(response)
        if(response.error === true){
            if(response.code===3 || response.code===2 || response.code===4){
                
                    props.history.replace('/');
                
            } else {
                swal("Algo salio mal!", `${response.message}`, "error")
            }
            setState({loading: false})
        } else {
            setState({data:response.routesData})
        }
    }

    const handleStarDate = (date) =>{
        if(date > endDate){
            setEndDate(date)
        } else {
            const limiteEnd = new Date(new Date(date).setDate(date.getDate() + 30))
            if(endDate > limiteEnd){
                setEndDate(limiteEnd)
            }
        }
        setStartDate(date);
    }
    
    const handleEndDate = (date) =>{
        if (date < startDate) {
            setStartDate(date)
        } else {
            const minimoStart = new Date(new Date(date).setDate(date.getDate() - 30))
            if (startDate < minimoStart) {
                setStartDate(minimoStart)
            }
        }
        setEndDate(date);
    }

    const handleSearchUsers =(e) => {
        e.preventDefault()
        const valUser = refUser.current.value;
        

        let query='';
      
        if(valUser !== undefined && valUser!== "" ){
            //setUserName(valUser)
            query+='userName='+valUser+'&';

        }
       
        
        if(startDate !== undefined && endDate!== undefined ){
            if(startDate<endDate ){
                query+=`startDate=${formattedDate(startDate)}&endDate=${formattedDate(endDate)}&`; 
                setEndDate();
                setStartDate();
            } else {
                swal("Algo salio mal!", 'Revisa los valores de fechas', "error")
            }
        }
        setParams(query);

    }

    function formattedDate(d = new Date()) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return `${day}/${month}/${year}`;
    }

    useEffect( () => {
        //console.log('useEffect')
        if(hasUser===false){
            props.history.replace('/');
        }
        fetchReportUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params]);

    if (state.loading === true) {
        return <PageLoading />;
      }


    
    return(
        <div className='container-fluid'>
            
         
         <RouteGSearchForm 
         className='mb-2 mt-2'
         refUser={refUser}
         

         startDate={startDate}
        endDate={ endDate}
        puedeConsultar={puedeConsultar}
        handleStarDate={handleStarDate}
        handleEndDate={handleEndDate}
        handleSearchUsers={handleSearchUsers}
         />
         {
            puedeGenerar===true && (
                <div className='d-flex justify-content-end'>
            <a className='btn btn-primary text-white mb-1'
            target='_blank' 
                            rel="noopener noreferrer" 
            href={`${URL_BASE_WS}/reports/routes/general/sheet?${params}&apikey=${user.apikey}`} 
             >
                <FontAwesomeIcon icon={faFileExcel} /> Excel
            </a>

         </div>

            )
         }

           

         
        
        <ReportRouteGList 
           // titleReport='Reporte de Usuarios ' 
            reportList={state.data} 
            //title='Nombre'
            //subtitle='Fecha'
            //description='Permisos'
            />


        </div>
            
    )
    

}
const mapStateToProps =  state => {
    return {
        user : state.user
    };
};
export default connect(mapStateToProps,null)(RouteReport);
